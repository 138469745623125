<template>
	<div class="user-level">
		<w-navTab titleText="等级列表"></w-navTab>
		<div class="head-top">
			<div class="head-picture"><img :src="user.avatar" alt="" /></div>
			<div class="head-title">{{ user.nickname }}</div>
			<div class="head-text">爱心值：{{ user.love_value }}</div>
		</div>

		<div class="middle">
			<div class="middle-conner">
				<div class="middle-item" v-for="(item, key) in loveLevel">
					<div class="item-picture">
						<img src="@/assets/img/medal1.png" alt="" v-if="key == 0" />
						<img src="@/assets/img/medal2.png" alt="" v-if="key == 1" />
						<img src="@/assets/img/medal3.png" alt="" v-if="key == 2" />
						<img src="@/assets/img/medal10.png" alt="" v-if="key == 3" />
						<img src="@/assets/img/medal4.png" alt="" v-if="key == 4" />
						<img src="@/assets/img/medal5.png" alt="" v-if="key == 5" />
						<img src="@/assets/img/medal6.png" alt="" v-if="key == 6" />
						<img src="@/assets/img/medal7.png" alt="" v-if="key == 7" />
						<img src="@/assets/img/medal8.png" alt="" v-if="key == 8" />
						<img src="@/assets/img/medal9.png" alt="" v-if="key == 9" />
					</div>
					<div class="item-right">
						<div :class="['item-title', { 'active-title-color': true === item.in_section }]">{{ item.level_name }}</div>
						<div class="item-text">{{ item.love_value }}</div>
					</div>
					<div :class="['middle-item-icon', { 'middle-item-icon-active': true == item.in_section }]"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserApi from '@/api/user';
export default {
	inject: ['className', 'districtType', 'themeColor'],
	name: 'UserLevel',
	data() {
		return {
			loveLevel: [],
			userLevel: '',
			user: ''
		};
	},
	created() {
		UserApi.getLoveLevelDetail()
			.then(result => {
				this.user = result.data.user;
				this.loveLevel = result.data.list;
			})
			.catch(error => {});
	},
	methods: {}
};
</script>

<style lang="less" scoped>
@import url('~@/assets/less/style.less');

.user-level.city {
	.head-top {
		background-image: url('~@/assets/img/test243.png');
		.head-picture {
			border: 2px solid #8ecaff;
		}
	}
}

.user-level.district {
	.head-top {
		background-image: url('../../assets/img/dj.png');
		.head-picture {
			border: 2px solid #ea6a6a;
		}
	}
}

.user-level {
	.head-top {
		width: 100%;
		height: 200px;
		background-size: cover;
		padding-top: 35px;
		text-align: center;
		box-sizing: border-box;
		
		.head-picture {
			width: 72px;
			height: 72px;
			border-radius: 100px;
			margin-left: 50%;
			transform: translateX(-50%);
			img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				object-fit: cover;
			}
		}
		.head-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 26px;
			color: #fff;
			margin-top: 10px;
		}
		.head-text {
			font-size: 15px;
			line-height: 26px;
			color: #fff;
			margin-top: 10px;
		}
	}

	.middle {
		padding: 10px;

		.middle-conner {
			padding: 10px 10px 5px 10px;
			background-color: #fff;
			border-radius: 6px;

			.middle-item {
				padding: 16px 56px;
				display: flex;
				position: relative;
				.border-bottom();

				.middle-item-icon {
					&::before {
						content: '';
						position: absolute;
						left: 29px;
						top: 50%;
						width: 12px;
						height: 12px;
						margin-top: -6px;
						background-color: #ccc;
						border-radius: 50%;
						z-index: 2;
					}

					&::after {
						content: '';
						position: absolute;
						left: 34px;
						top: 0;
						width: 2px;
						height: 100%;
						background-color: #ccc;
						z-index: 1;
					}

					&.middle-item-icon-active:before {
						background-color: #3377ff;
					}
				}

				&:last-child .middle-item-icon:after {
					height: 50%;
				}

				&:first-child .middle-item-icon:after {
					height: 50%;
					top: 50%;
				}

				.item-picture {
					width: 30px;
					min-width: 30px;
					height: 30px;
					margin-top: 5px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.item-right {
					margin-left: 5px;
					.item-title {
						font-size: 16px;
						line-height: 24px;
						color: #333;
					}
					.active-title-color {
						color: #3377ff;
					}
					.item-text {
						font-size: 13px;
						line-height: 16px;
						color: #666;
					}
				}

				.middle-item-border {
					width: 100%;
					border-bottom: 1px solid #f2f2f2;
				}
				.middle-item-border:last-child {
					border-bottom: 0;
				}
				.middle-item-right-margin {
					margin-left: 50px;
				}
			}
			.middle-item:last-child {
				border: 0;
				&::after {
					content: '';
					border: 0;
				}
			}
		}
	}
}
</style>
